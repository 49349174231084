import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm,
    BooleanField, BooleanInput, TextField, EditButton,
    TextInput, Toolbar, SaveButton, useListContext
} from 'react-admin';
import Button from "@material-ui/core/Button";

const listReportFilters = [
    <TextInput source="q" label="Search" alwaysOn/>,
    // <BooleanInput source="status" label="Status"/>,
    <BooleanInput source="hidden" label="Hidden"/>,
];

const ListReport = ({record}) => {
    console.log(record);
    return (
        <Datagrid data={record.userReports} total={record.userReports.length}>
            <TextField label="ID" source="id"/>
            <BooleanField source="isReviewed"/>
            <TextField label="Reason" source="reason"/>
            <TextField label="Username" source="reportedBy.username"/>
            <TextField label="Reviewed By" source="reviewedBy.username"/>
            <EditButton basePath="/user_reports" />
        </Datagrid>
    )
}

const ListReportGrouped = ({ids, data, basePath}) => {
    const groupReportByReported = {};

    const userIds = [];

    for (const id of ids) {
        const report = data[id];

        if (groupReportByReported[report.user.id] === undefined) {
            userIds.push(report.user.id)
            groupReportByReported[report.user.id] = {
                id: report.user.id,
                user: report.user,
                userReports: {
                    [report.id]: report
                },
                userReportIds: [report.id]
            }
        } else {
            groupReportByReported[report.user.id].userReports[report.id] = report;
            groupReportByReported[report.user.id].userReportIds.push(report.id)
        }
    }

    return (
        <Datagrid expand={ListReport} data={groupReportByReported} ids={userIds}>
            <TextField label="User ID" source="id"/>
            <TextField label="User" source="user.username"/>
        </Datagrid>
    );
}

export const UserReportList = (props) => {
    return (
        <List filters={listReportFilters} {...props} bulkActionButtons={false}>
            <ListReportGrouped {...props}/>
        </List>
    );
}

const UserReportEditToolbar = props => (
    <Toolbar {...props} >
        <Button style={{marginRight: 50}} variant="outlined" onClick={e => window.history.back()}> Back </Button>
        <SaveButton/>
    </Toolbar>
);

export const UserReportEdit = (props) => {
    const transform = data => ({
        isReviewed: data.isReviewed
    });

    return (
        // @ts-ignore
        <Edit title="Edit User Report" {...props} transform={transform}>
            <SimpleForm toolbar={<UserReportEditToolbar/>}>
                <TextField label="ID" source="id"/>
                <TextField label="User ID" source="user.id"/>
                <TextField label="User Owner" source="user.username"/>
                <TextField label="Reported By" source="reportedBy.username"/>
                <TextField label="Reviewed By" source="reviewedBy.username"/>
                <TextField label="Reason" source="reason"/>
                <BooleanInput source="isReviewed"/>
            </SimpleForm>
        </Edit>
    )
}

export const UserReportCreate = (props) => (
    <Create title="Creating a user report is disabled" {...props}>
    </Create>
);
