import * as React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoginButton = (props) => {
    return (
        <Button onClick={props.onClick} disabled={props.loading}>
            {props.loading && (
                <CircularProgress size={18} thickness={2}/>
            )}
            Login with metamask
        </Button>

    );
};

export default LoginButton;