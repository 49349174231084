import * as React from "react";
import axios from 'axios';
import env from "react-dotenv";
import tokenAbi from "../libs/assets/tokenAbi.json";
import {
    useDataProvider, List, Datagrid, TextField,SelectField, SelectInput
} from 'react-admin';
import Button from "@material-ui/core/Button";
import {useEffect} from 'react';
import {BigNumber, ethers} from 'ethers';

const apiUrl = env.HYVE_API_ENTRYPOINT;

const statusChoices = [
    {id: 0, name: 'Pending'},
    {id: 1, name: 'Accepted'},
    {id: 2, name: 'Rejected'},
    {id: 3, name: 'Canceled'},
];

const withdrawRequestFilters = [
    <SelectInput source="status" label="Status" choices={statusChoices}/>,
];

export const WithdrawRequestList = props => (
    <List filters={withdrawRequestFilters} {...props} bulkActionButtons={false} >
        <Datagrid>
            <TextField label="Owner" source="user.publicAddress"/>
            <SelectField source="status" choices={statusChoices}/>
            <TextField label="Blockchain" source="blockchain.name"/>
            <TextField label="Amount" source="referralActionsAmount"/>
            <HandleTransferTokens/>
        </Datagrid>
    </List>
);

export const HandleTransferTokens = (props) => {
    const dataProvider = useDataProvider();
    // @ts-ignore
    const metamask = window.ethereum;
    const targetChainId = '0x' +  parseInt(props.record.blockchain.chainId).toString(16);
    var hyveBlockchainCurrency;

    async function CheckChainAndTransfer() {

        if (metamask.chainId != targetChainId) {
            await metamask.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: targetChainId,
                    rpcUrls: [
                        props.record.blockchain.rpcUrl
                    ]
                }]
            });
        } else {
            TransferTokens();
        }
    }

    const networkChanged = (chainId) => {
        if (chainId == targetChainId) {
            TransferTokens();
        }
    }

    useEffect(() => {
        metamask.on("chainChanged", networkChanged);

        return () => {
            metamask.removeListener("chainChanged", networkChanged);
        };
    }, []);

    async function TransferTokens(){
        const provider = await new ethers.providers.Web3Provider(metamask);
        const signer = provider.getSigner();

        await axios.get(apiUrl + '/currencies?name=Hyve', {withCredentials: true})
        .then(res => {
            hyveBlockchainCurrency = res.data['hydra:member'][0].blockchainCurrencies.find((el) => {return el.blockchain.id == props.record.blockchain.id});
        })
        .catch(err => {
            console.log(err);
        });
        
        const hyveContract = new ethers.Contract(
            hyveBlockchainCurrency.erc20Address,
            tokenAbi,
            signer
        );

        try {
            var transferAmount = props.record.referralActionsAmount * 10 ** hyveBlockchainCurrency.decimalPlaces;

            const isSuccess = await hyveContract.transfer(
                props.record.user.publicAddress,
                BigNumber.from(transferAmount.toString()),
            );

            if (isSuccess) {
                await dataProvider.update('withdraw_requests',{
                    previousData: undefined,
                    id: props.record.id,
                    data: {
                        status: 1
                    }
                })
            }
        } catch (err) {
            console.log(err);
        }
    }

    if (props.record.status = 0) {
        return (
            <Button onClick={CheckChainAndTransfer}>Send tokens</Button>
        )
    } else {
        return null;
    }
}