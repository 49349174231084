import * as React from "react";
import {
    useDataProvider,
    Loading,
    Error,
    SimpleForm,
    TextInput,
    required,
    Toolbar,
    SaveButton,
    SaveContextProvider,
    BooleanInput,
    useNotify,
    NumberInput,
    minValue,
    maxValue,
    regex,
    TextField,
    BooleanField,
    SimpleShowLayout,
    NumberField,
    ShowContext, SelectField
} from 'react-admin';
import {useState, useEffect, useCallback, useMemo} from 'react';
import { useHistory } from 'react-router-dom';
import {DataGrid, GridColDef, GridApi, GridCellValue} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import VisibilityIcon  from '@material-ui/icons/Visibility';
import SettingsIcon  from '@material-ui/icons/Settings';
import {callSetTaxToken, divideString, multiplyFloatString} from "../utils/Utils";
import {BigNumber} from "ethers";


export const BlockchainCurrencyList = (props) => {
    const history = useHistory();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState(null);
    const [error, setError] = useState(null);
    const currencyId = decodeURIComponent((props.match).params.currencyId);

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 0, hide: true},
        {field: 'blockchain', headerName: 'Blockchain', width: 150},
        {field: 'exchangePathAddress', headerName: 'Exchange Paths', width: 200},
        {field: 'erc20', headerName: 'ERC-20 Address', width: 150},
        {field: 'decimalPlaces', headerName: 'Decimal Places', width: 150},
        {field: 'isStable', headerName: 'Stable', width: 100},
        {field: 'status', headerName: 'Status', width: 100},
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            width: 250,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api: GridApi = params.api;
                    const thisRow: Record<string, GridCellValue> = {};

                    api.getAllColumns()
                        .filter((c) => c.field !== "__check__" && !!c)
                        .forEach(
                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                        );

                    const dataRow = rows.find(item => item.id == thisRow.id);
                    if(dataRow.exists) return history.push(`/blockchain_currencies/${currencyId}/edit/${dataRow.blockchainId}`);

                    return history.push(`/blockchain_currencies/${currencyId}/create/${dataRow.blockchainId}`);
                };
                const onClickView = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api: GridApi = params.api;
                    const thisRow: Record<string, GridCellValue> = {};

                    api.getAllColumns()
                        .filter((c) => c.field !== "__check__" && !!c)
                        .forEach(
                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                        );

                    const dataRow = rows.find(item => item.id == thisRow.id);
                    return history.push(`/blockchain_currencies/${currencyId}/view/${dataRow.blockchainId}`);
                };

                return (<>
                        <Button variant="contained" size="small" startIcon={<VisibilityIcon />} onClick={onClickView} sx={{mr: 1}}>View</Button>
                        <Button variant="outlined" size="small" startIcon={<SettingsIcon />} onClick={onClick}>Manage</Button>
                    </>);
            }
        },
    ];

    async function getTableData() {
        const { data: blockchains } = await dataProvider.getList('blockchains', {
            pagination: {page: undefined, perPage: undefined},
            sort: {field: undefined, order: undefined},
            filter: {}
        });

        const {data: blockchainCurrencies} = await dataProvider.getList('blockchain_currencies', {
            pagination: {page: undefined, perPage: undefined},
            sort: {field: undefined, order: undefined},
            filter: {'currency.id': currencyId}
        });

        const rows = blockchains.map((blockchain) => {
            const blockchainCurrency = blockchainCurrencies.find(blockchainCurrency => blockchainCurrency.blockchain.id == blockchain.id);

            if(blockchainCurrency) {
                return {
                    id: blockchain.id, //cannot be null
                    blockchain: blockchain.name,
                    blockchainId: blockchain.id,
                    blockchainCurrencyId: blockchainCurrency.id,
                    erc20: blockchainCurrency.erc20Address,
                    decimalPlaces: blockchainCurrency.decimalPlaces,
                    exchangePathAddress: blockchainCurrency.exchangePathAddresses ? blockchainCurrency.exchangePathAddresses.join(',') : '-',
                    isStable: blockchainCurrency.isStable ? 'Yes' : 'No',
                    status: blockchainCurrency.status === 0 ? 'Disabled' : 'Active',
                    exists: true
                }
            }

            return {
                id: blockchain.id,//cannot be null
                blockchain: blockchain.name,
                blockchainId: blockchain.id,
                blockchainCurrencyId: '-',
                erc20: '-',
                decimalPlaces: '-',
                exchangePathAddress: '-',
                isStable: '-',
                status: '-',
                exists: false
            }
        })

        setRows(rows);
        setLoading(false);
    }

    useEffect(() => {
        try {
            getTableData();
        } catch (e) {
            setError(error);
        }

    }, []);

    if (loading) return <Loading/>;
    if (error) return <Error error={error}/>;

    return (
        <>
            <div style={{height: '100%', width: '100%'}}>
                <DataGrid rows={rows} columns={columns}/>
            </div>
            <div>
                <Button variant="outlined" sx={{mt: 3}} onClick={ e => window.history.back()}> Back </Button>
            </div>
        </>
    );
}
export const BlockchainCurrencyView = (props) => {
    const dataProvider = useDataProvider();
    const currencyId = decodeURIComponent((props.match).params.currencyId);
    const blockchainId = decodeURIComponent((props.match).params.blockchainId);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [blockchainCurrency, setBlockchainCurrency] = useState(null);
    const statusChoices = [
        {id: 0, name: 'Inactive'},
        {id: 1, name: 'Active'},
    ];
    const notify = useNotify();

    async function getData() {
        try {
            // @ts-ignore
            const { data: blockchainCurrency } = await dataProvider.getOneFromFilters('blockchain_currencies', {
                filter: {
                    'currency.id': currencyId,
                    'blockchain.id': blockchainId,
                }
            });

            if(blockchainCurrency.exchangePathAddresses){
              blockchainCurrency.exchangePathAddresses = blockchainCurrency.exchangePathAddresses.length > 0 ? blockchainCurrency.exchangePathAddresses.join(', ') : '-'
            }
            else blockchainCurrency.exchangePathAddresses = '-';

            setBlockchainCurrency(blockchainCurrency);
            setLoading(false);

        } catch(e) {
            notify(e.message, 'error', {}, false, 10000);
            setError(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        try {
            getData();
        } catch (e) {
            notify(e.message, 'error', {}, false, 10000);
            setError(error);
        }

    }, []);


    if (loading) return <Loading/>;
    if (error) return <Error error={error}/>;

    return (
        <>
            <SimpleShowLayout record={blockchainCurrency}>
                <TextField label="Blockchain Currency ID" source="id"/>
                <TextField label="ERC-20 Address" source="erc20Address"/>
                <TextField label="Decimal Places" source="decimalPlaces"/>
                <TextField label="Exchange Path Addresses" source="exchangePathAddresses"/>
                <BooleanField label="Is Stable" source="isStable"/>
                <SelectField source="status" choices={statusChoices}/>
            </SimpleShowLayout>
            <div>
                <Button variant="outlined" sx={{mt: 3}} onClick={ e => window.history.back()}> Back </Button>
            </div>
        </>
    )
}


const BlockchainCurrencyEditToolbar = props => (
    <Toolbar {...props} >
        <Button style={{ marginRight: 50 }} variant="outlined" onClick={ e => window.history.back()}> Back </Button>
        <SaveButton
            label="Save"
            // transform={data => ({ ...data, notify: true })}
            submitOnEnter={false}
            basePath={null}
        />
    </Toolbar>
);


const ethAddressValidator = [
    required(),
    regex(/^0x[a-fA-F0-9]{40}$/, 'Must be a valid ERC-20 Address')
]

const currencyDecimalPlacesValidators = [
    required(),
    minValue(0, 'Should be higher or equal to 0'),
    maxValue(32, 'Should be less or equal to 32'),
];

export const BlockchainCurrencyEdit = (props) => {
    const dataProvider = useDataProvider();
    const currencyId = decodeURIComponent((props.match).params.currencyId);
    const blockchainId = decodeURIComponent((props.match).params.blockchainId);
    const [saving, setSaving] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [blockchainCurrency, setBlockchainCurrency] = useState(null);
    const [hasExchangePath, setHasExchangePath] = useState(false);
    const notify = useNotify();

    async function getData() {
        const { data: currency } = await dataProvider.getOne('currencies', {id: currencyId});
        // @ts-ignore
        const { data: blockchainCurrency } = await dataProvider.getOneFromFilters('blockchain_currencies', {
            filter: {
                'currency.id': currencyId,
                'blockchain.id': blockchainId,
            }
        });

        if(blockchainCurrency.exchangePathAddresses && blockchainCurrency.exchangePathAddresses.length > 0) {
            setHasExchangePath(true);
        }

        setCurrency(currency);
        setBlockchainCurrency(blockchainCurrency);
        setLoading(false);
    }

    useEffect(() => {
        try {
            getData();
        } catch (e) {
            notify(e.message, 'error', {}, false, 10000);
            setError(error);
        }

    }, []);

    const clickHasExchangePath = (e) => {
        setHasExchangePath(e.target.checked);
    }

    const handleSave = useCallback(async (values) => {
        setSaving(true);

        const { data: blockchain } = await dataProvider.getOne('blockchains', {id: blockchainId});

      const formData: {
        id: string,
        isStable: boolean,
        erc20Address: string,
        decimalPlaces: number,
        status: number,
        exchangePathAddresses?: [],
        exchangeRate?: string,
      } = {
        id: values.id,
        isStable: values.isStable === null ? false : values.isStable,
        erc20Address: values.erc20Address,
        decimalPlaces: values.decimalPlaces,
        status: 0 //disabled
      }

        if(values.exchangePathAddresses && values.exchangePathAddresses.length > 0) {
            if(typeof values.exchangePathAddresses === 'string') {
                formData.exchangePathAddresses = values.exchangePathAddresses.split(',').map(item => item.trim());
            } else {
                formData.exchangePathAddresses = values.exchangePathAddresses
            }
        }

        try {
            await dataProvider.update('blockchain_currencies',{
                previousData: undefined,
                id: values.id,
                data: formData
            })
            setSaving(false);
            notify('Blockchain Currency data successfully saved.', 'success', {}, false, 10000);
        } catch(e) {
            setError(e);
            console.error(e);
        }

    }, [dataProvider]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);

    if (loading) return <Loading/>;
    if (error) return <Error error={error}/>;

    return (
        <SaveContextProvider value={saveContext}>
            <SimpleForm handleSubmit={handleSave} save={handleSave} saving={saving} toolbar={<BlockchainCurrencyEditToolbar/>} record={blockchainCurrency}>
                <TextInput source="id" style={{display: 'none'}}/>
                <BooleanInput source="hasExchangePath" defaultValue={hasExchangePath}  onClick={clickHasExchangePath} />
                {hasExchangePath &&
                    <TextInput source="exchangePathAddresses" fullWidth={true} validate={[required()]}
                               // defaultValue={blockchainCurrency.exchangePathAddress ? blockchainCurrency.exchangePathAddress : ''}
                               helperText="You can insert multiple addresses separated by comma (,)"
                               label="Exchange Path Addresses (separated by comma)"
                    />
                }

                <BooleanInput source="isStable"
                              // defaultValue={blockchainCurrency.isStable == null ? false : blockchainCurrency.isStable}
                />
                <TextInput source="erc20Address" fullWidth={true} validate={ethAddressValidator}/>
                <NumberInput source="decimalPlaces" validate={currencyDecimalPlacesValidators}/>
            </SimpleForm>
        </SaveContextProvider>
    )
}

export const BlockchainCurrencyCreate = (props) => {
    const dataProvider = useDataProvider();
    const currencyId = decodeURIComponent((props.match).params.currencyId);
    const blockchainId = decodeURIComponent((props.match).params.blockchainId);
    const [blockchain, setBlockchain] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [hasExchangePath, setHasExchangePath] = useState(false);
    const notify = useNotify();

    const clickHasExchangePath = (e) => {
        setHasExchangePath(e.target.checked);
    }

    const handleSave = useCallback(async (values) => {
        setSaving(true);

        const { data: blockchain } = await dataProvider.getOne('blockchains', {id: blockchainId});

        const formData: {
            currency: string,
            blockchain: string,
            isStable: boolean,
            erc20Address: string,
            decimalPlaces: number,
            status: number,
            exchangePathAddresses?: [],
        } = {
            currency: `/currencies/${currencyId}`,
            blockchain: `/blockchains/${blockchainId}`,
            isStable: values.isStable,
            erc20Address: values.erc20Address,
            decimalPlaces: values.decimalPlaces,
            status: 0 //disabled
        }

        if(values.exchangePathAddresses && typeof values.exchangePathAddresses === 'string') {
            formData.exchangePathAddresses = values.exchangePathAddresses.split(',').map(item => item.trim());
            values.exchangeRate = null;
        }

        try {
            await dataProvider.create('blockchain_currencies',{
                data: formData
            })
            setSaving(false);
            notify('Blockchain Currency data successfully saved.', 'success', {}, false, 10000);

        } catch(e) {
            setError(e);
            console.error(e);
        }

    }, [dataProvider]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);

    if (error) return <Error error={error}/>;

    return (
        <SaveContextProvider value={saveContext}>
            <SimpleForm handleSubmit={handleSave} save={handleSave} saving={saving} toolbar={<BlockchainCurrencyEditToolbar/>}>
                <BooleanInput source="hasExchangePath" defaultChecked={hasExchangePath} onClick={clickHasExchangePath}/>
                {hasExchangePath &&
                    <TextInput source="exchangePathAddresses" fullWidth={true} validate={[required()]}/>
                }
                <BooleanInput source="isStable" defaultValue={false}/>
                <TextInput source="erc20Address" fullWidth={true} validate={ethAddressValidator}/>
                <NumberInput source="decimalPlaces" validate={currencyDecimalPlacesValidators}/>
            </SimpleForm>
        </SaveContextProvider>
    )
}
