import axios from 'axios';
import env from "react-dotenv";
import * as React from "react";
import { useState, useEffect } from 'react';

const apiUrl = env.HYVE_API_ENTRYPOINT;

function StakingInformation() {
    const [stakingInfo, setStakingInfo] = useState([]);
    const [percentageHyveStaked, setPercentageHyveStaked] = useState('');

    useEffect(() => {
        axios.get(apiUrl + '/staking/general', {withCredentials: true})
            .then(res => {
                setPercentageHyveStaked((res.data.reduce((sum, current) => sum + current.totalHyveStaked / Math.pow(10, 18), 0) / 34250000 * 100).toFixed(2));
            })
            .catch(err => {
                console.log(err);
            })
        }, []);

    return (
        <div>
            HYVE staked: {percentageHyveStaked}% (assuming total circulating supply of 34.25M)
        </div>
    )
}

export default StakingInformation;