import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm,
  TextField, EditButton, useNotify,
  TextInput, required, useDataProvider, Toolbar, SaveButton, ArrayInput, SimpleFormIterator

} from 'react-admin';
import Button from "@material-ui/core/Button";

export const MajorPatchList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id"/>
        <TextField source="name"/>
        <TextField source="tag"/>
        <TextField source="overview" />
        <EditButton basePath="/major_patches"/>
      </Datagrid>
    </List>
  );
}
const EditToolbar = props => (
  <Toolbar {...props} >
    <Button style={{ marginRight: 50 }} variant="outlined" onClick={ e => window.history.back()}> Back </Button>
    <SaveButton />
  </Toolbar>
);
export const MajorPatchEdit = (props) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const majorPatchSave = async (vars) => {
    if(vars.tag) {
      let params = {
        "name": vars.name,
        "title": vars.title,
        "features": vars.features,
        "removed": vars.removed,
        "fixes": vars.fixes,
        "improvements": vars.improvements,
        "tag": vars.tag,
        "overview": vars.overview,
      }
      dataProvider.update('major_patches', {"data": params, "id": vars.id, previousData:null}).then(() => {
        notify(`Major Patch updated`);
        window.history.back();
      }).catch((err) => {
        console.log(err);
        notify("Error while updating major patch. Please check the required fields!")
      })
    } else {
      notify("Error.");
    }
  };

  return (
    // @ts-ignore
    <Edit {...props}>
      <SimpleForm save={majorPatchSave} toolbar={<EditToolbar/>}>
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="tag" validate={[required()]}/>
        <TextInput source="overview" validate={[required()]}/>
        <ArrayInput source="features" >
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="removed" >
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="fixes" >
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="improvements">
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
}
export const MajorPatchCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const majorPatchSave = async (vars) => {
    if(vars.tag) {
      let params = {
        "name": vars.name,
        "title": vars.title,
        "features": vars.features,
        "removed": vars.removed,
        "fixes": vars.fixes,
        "improvements": vars.improvements,
        "tag": vars.tag,
        "overview": vars.overview,
      }
      dataProvider.create('major_patches', {data: params}).then(() => {
        notify(`Major Patch created`);
        window.history.back();
      }).catch((err) => {
        console.log(err);
        notify("Error while creating major patch. Please check the required fields!")
      })
    } else {
      notify("Error.");
    }
  };
  return (
    <Create {...props}>
      <SimpleForm save={majorPatchSave} >
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="tag" validate={[required()]}/>
        <TextInput source="overview" validate={[required()]}/>
        <ArrayInput source="features" validate={[]} >
          <SimpleFormIterator>
            <TextInput source="name" validate={[]} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="removed" validate={[]}>
          <SimpleFormIterator>
            <TextInput source="name" validate={[]} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="fixes">
          <SimpleFormIterator>
            <TextInput source="name"/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="improvements">
          <SimpleFormIterator>
            <TextInput source="name"/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
};
