import env from "react-dotenv";

export const apiResources = {
    listing: {
        name: 'listings',
        url: getResourceUrl('listings')
    },
    users: {
        name: 'users',
        url: getResourceUrl('users')
    },
    authenticationToken: {
        name: 'authentication_token',
        url: getResourceUrl('authentication_token')
    },
    operators: {
        name: 'operators',
        url: getResourceUrl('operators'),
        context: 'operators'
    },
}

function getResourceUrl(resource) {
    return `${env.HYVE_API_ENTRYPOINT}/${resource}`
}

