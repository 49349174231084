import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm,
  TextField, EditButton, ImageField, ImageInput, DateTimeInput, useNotify,
  TextInput, required, useDataProvider

} from 'react-admin';

const toUTC= (value) => {
  if(typeof value === "string") {
    value = new Date(value);
  }
  return value.toISOString();
}

export const PopupList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="name"/>
                <TextField source="startDate"/>
                <TextField source="endDate"/>
                <ImageField source="asset.remoteUri" title="title" label="Image" />
                <TextField source="url"/>

                <EditButton basePath="/popups"/>
            </Datagrid>
        </List>
    );
}


export const PopupEdit = (props) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const popupSave = async (vars) => {

    let params = {
      "name": vars.name,
      "startDate": toUTC(vars.startDate),
      "endDate": toUTC(vars.endDate),
      "url": vars.url

    }

    if(vars.pictures) {
      let formData = new FormData();
      formData.append('file', vars.pictures.rawFile);

      dataProvider.create('assets/upload_file', {data: formData}).then((asset) => {
        params["asset"] = '/assets/' + asset.data.id
        notify("Asset Created")

        dataProvider.update('popups', {data: params,"id": vars.id, previousData:null}).then((popup) => {
          notify("Popup Edited")
        }).catch((err) => {
          console.log(err);
          notify("Error while editing popup.  Please check the dates!")
        })
      }).catch((err) => {
        console.log(err);
        notify("Error while editing asset")
      })
    } else {
      params["id"] = vars.id
      dataProvider.update('popups', {"data": params, "id": vars.id, previousData:null}).then((popup) => {
        notify("Popup Edited")
      }).catch((err) => {
        console.log(err);
        notify("Error while editing popup. Please check the dates!")
      })
    }

  };

    return (
        // @ts-ignore
        <Edit title="Edit Currency" {...props}>
            <SimpleForm save={popupSave} >
              <TextInput source="name" validate={[required()]}/>
              <TextField label={"Date will be converted in UTC and show to all people at the same time"}></TextField>
              <DateTimeInput source="startDate" validate={[required()]}/>
              <TextField label={"Date will be converted in UTC and show to all people at the same time"}></TextField>
              <DateTimeInput source="endDate" validate={[required()]} />

              <TextInput source="url"/>

              <ImageField source="asset.remoteUri" title="title" label="Current Image" />
              <ImageInput source="pictures" label="New Image" accept="image/*" >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleForm>
        </Edit>
    )
}




export const PopupCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const popupSave = async (vars) => {

    let formData = new FormData();
    formData.append('file', vars.pictures.rawFile);

    dataProvider.create('assets/upload_file', {data: formData}).then((asset) => {
      console.log(asset);
      let params = {
        "name": vars.name,
        "startDate": toUTC(vars.startDate),
        "endDate": toUTC(vars.endDate),
        "asset": '/assets/' + asset.data.id,
        "url": vars.url
      }
      notify(`Asset Created`);

      dataProvider.create('popups', {data: params}).then((asset) => {
        notify(`Popup created`);
      }).catch((err) => {
        console.log(err);
        notify("Error while creating popup. Please check the dates!")
      })
    }).catch((err) => {
      console.log(err);
      notify("Error while creating asset")
    })
  };
  return (
    <Create {...props}>
      <SimpleForm save={popupSave}>
        <TextInput source="name" validate={[required()]}/>
        <TextField label={"Date is represented in UTC time"}></TextField>
        <DateTimeInput source="startDate" validate={[required()]}/>
        <TextField label={"Date is represented in UTC time"}></TextField>
        <DateTimeInput source="endDate"validate={[required()]} />

        <TextInput source="url"/>

        <ImageInput source="pictures" label="Related pictures" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )
}


