import env from "react-dotenv";
import {fetchUtils} from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = env.HYVE_API_ENTRYPOINT;
const httpClient = (url, options: IOptions = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/ld+json' });
    }

    options['credentials'] = 'include';
    return fetchUtils.fetchJson(url, options);
};

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            pagination: !!params.pagination,
            showHidden: 1,
            ...params.filter
        };

         if(field && order) query.sort = JSON.stringify([field, order]);
         if(page) query.page = JSON.stringify(page);
         if(perPage) query.itemsPerPage = JSON.stringify(perPage);

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ json }) => ({
            data: json['hydra:member'],
            total: json['hydra:totalItems'],
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getOneFromFilters: (resource, params) =>{
        const query = {
            ...params.filter
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json['hydra:member'][0] }));
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

  update: (resource, params) => {
    let url = `${apiUrl}/${resource}`;

    if (resource === 'listings') {
      url += `/${params.previousData.slug}`;
    }
    else {
      url += `/${params.id}`;
    }
    return httpClient(url, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
      let theBody = {}

      if(typeof params.data === "object" && params.data.constructor.name === "FormData") {
        theBody = params.data;
      } else {
        theBody = JSON.stringify(params.data)
      }
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: theBody,
      }).then(({json}) => ({
        data: {...params.data, id: json.id},
      }))
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    }
};

interface IOptions {
    headers?: Headers,
    method?: string,
    body?: any
}
