import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm,
    BooleanField, BooleanInput, TextField, EditButton,
    TextInput, SelectField, SelectInput, Toolbar, SaveButton,
    NumberInput,
    required, minValue, maxValue, ListButton, useDataProvider, Loading, Error,
    ImageInput, useNotify, ImageField
} from 'react-admin';
import { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";

const currencyFilters = [
    <TextInput source="name" label="Search" alwaysOn/>,
    // <BooleanInput source="status" label="Status"/>,
    // <BooleanInput source="hidden" label="Hidden"/>,
];
const statusChoices = [
    {id: 0, name: 'Inactive'},
    {id: 1, name: 'Active'},
];

const ManageButton = ({record}) => (
    <ListButton basePath={`/blockchain_currencies/${record.id}`} label="Manage" record={record}/>
);


export const CurrencyList = (props) => {
    return (
        <List filters={currencyFilters} {...props} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="name"/>
                <TextField source="code"/>
                <BooleanField source="isCrypto"/>
                <BooleanField source="isHyve"/>
                <SelectField source="status" choices={statusChoices}/>
                <ImageField source="currencyIcon.remoteUri" label="Currency Icon" />
                <ImageField source="paymentIcon.remoteUri" label="Payment Icon" />
                <TextField source="cmcId" label="CMC ID"/>
                <EditButton basePath="/currencies"/>
                <ManageButton record={record => record}/>
            </Datagrid>
        </List>
    );
}

const CurrencyEditToolbar = props => (
    <Toolbar {...props} >
        <Button style={{ marginRight: 50 }} variant="outlined" onClick={ e => window.history.back()}> Back </Button>
        <SaveButton/>
    </Toolbar>
);

export const UploadFile = async (file, dataProvider) => {
  let formData = new FormData();
  formData.append('file', file);
  return await dataProvider.create('assets/upload_file', {data: formData}).catch((err) => {
    console.log(err);
    return false
  })

}

export const CurrencyEdit = (props) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const CurrencySave = async (vars) => {

    let currencyIcon = null;
    let paymentIcon = null;

    if (vars.currencyIcon.rawFile) {
      currencyIcon = await UploadFile(vars.currencyIcon.rawFile, dataProvider)
      if (!currencyIcon) {
        notify(`Currency Icon Asset Created`);
        return;
      }
    }

    if (vars.paymentIcon.rawFile) {
      paymentIcon = await UploadFile(vars.paymentIcon.rawFile, dataProvider)
      if (!paymentIcon) {
        notify(`Payment Icon Asset Created`);
        return;
      }
    }

    let params = {
      "code": vars.code,
      "name": vars.name,
      "description": vars.description,
      "isCrypto": vars.isCrypto,
      "isHyve": vars.isHyve,
      "status": vars.status,
      "cmcId": vars.cmcId
    }
    if(currencyIcon) {
      params['currencyIcon'] = '/assets/' + currencyIcon.data.id;
    }

    if(paymentIcon) {
      params['paymentIcon'] = '/assets/' + paymentIcon.data.id;
    }

    dataProvider.update('currencies', {data: params, "id": vars.id, previousData: null}).then((currency) => {
      notify("Currency Edited")
      window.history.back();
    }).catch((err) => {
      console.log(err);
      notify("Error while editing currency.")
    })
  };

  return (
    // @ts-ignore
    <Edit title="Edit Currency" {...props}>
      <SimpleForm save={CurrencySave} toolbar={<CurrencyEditToolbar/>} >
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="code" validate={[required()]}/>
        <TextInput multiline source="description" validate={[required()]}/>
        {/*<BooleanInput disabled source="isCrypto" defaultValue={true} validate={[required()]}/>*/}
        {/*<BooleanInput disabled source="isHyve" defaultValue={false} validate={[required()]}/>*/}
        <SelectInput source="status" choices={statusChoices} validate={[required()]}/>
        <NumberInput source="cmcId" validate={[required()]}/>
        <ImageField source="currencyIcon.remoteUri" label="Current Currency Icon"/>
        <ImageInput source="currencyIcon" label="Replacing Currency Icon" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <ImageField source="paymentIcon.remoteUri" label="Current Payment Icon"/>
        <ImageInput source="paymentIcon" label="Replacing Payment Icon" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title"/>
        </ImageInput>

      </SimpleForm>
    </Edit>
  )
}

export const CurrencyCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const CurrencySave = async (vars) => {
    const currencyIcon = await UploadFile(vars.currencyIcon.rawFile, dataProvider)
    if (!currencyIcon) {
      notify(`Currency Icon Asset Created`);
      return;
    }

    const paymentIcon = await UploadFile(vars.paymentIcon.rawFile, dataProvider)
    if (!paymentIcon) {
      notify(`Payment Icon Asset Created`);
      return;
    }

    if (currencyIcon && paymentIcon) {
      let params = {
        "code": vars.code,
        "name": vars.name,
        "description": vars.description,
        "isCrypto": vars.isCrypto,
        "isHyve": vars.isHyve,
        "status": vars.status,
        "currencyIcon": '/assets/' + currencyIcon.data.id,
        "paymentIcon": '/assets/' + paymentIcon.data.id,
        "cmcId": vars.cmcId
      }
      notify('Asset Created');

      dataProvider.create('currencies', {data: params}).then(() => {
        notify(`Currency created`);
        window.history.back();
      }).catch((err) => {
        console.log(err);
        notify("Error while creating currency.")
      })
    } else {
      notify("Error while creating currency. You need to add both icons!")
    }
  };

  return (
    <Create {...props}>
      <SimpleForm save={CurrencySave} toolbar={<CurrencyEditToolbar/>}>
        <TextInput source="code" validate={[required()]}/>
        <TextInput source="name" validate={[required()]}/>
        <TextInput multiline source="description" validate={[required()]}/>
        <SelectInput source="status" choices={statusChoices} validate={[required()]}/>
        <div style={{display: "none"}}>
          <BooleanInput disabled source="isCrypto" defaultValue={true} validate={[required()]}/>
          <BooleanInput disabled source="isHyve" defaultValue={false} validate={[required()]}/>
        </div>
        <NumberInput source="cmcId" validate={[required()]}/>
        <ImageInput source="currencyIcon" label="Related pictures" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <ImageInput source="paymentIcon" label="Related pictures" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title"/>
        </ImageInput>
      </SimpleForm>
    </Create>
  )
};
