import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm,
  TextField, EditButton, useNotify,
  TextInput, required, useDataProvider, Toolbar, SaveButton, ArrayInput, SimpleFormIterator, SelectInput

} from 'react-admin';
import Button from "@material-ui/core/Button";
import {useEffect, useState} from "react";

export const MinorPatchList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id"/>
        <TextField source="name"/>
        <TextField source="tag"/>
        <TextField source="parent"/>
        <TextField source="overview" />
        <EditButton basePath="/minor_patches"/>
      </Datagrid>
    </List>
  );
}

const EditToolbar = props => (
  <Toolbar {...props} >
    <Button style={{ marginRight: 50 }} variant="outlined" onClick={ e => window.history.back()}> Back </Button>
    <SaveButton />
  </Toolbar>
);
export const MinorPatchEdit = (props) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const minorPatchSave = async (vars) => {
    if(vars.tag) {
      let params = {
        "name": vars.name,
        "title": vars.title,
        "parent": vars.parent,
        "features": vars.features,
        "removed": vars.removed,
        "fixes": vars.fixes,
        "improvements": vars.improvements,
        "tag": vars.tag,
        "overview": vars.overview,
      }
      dataProvider.update('minor_patches', {"data": params, "id": vars.id, previousData:null}).then(() => {
        notify(`Minor Patch updated`);
        window.history.back();
      }).catch((err) => {
        console.log(err);
        notify("Error while updating minor patch. Please check the required fields!")
      })
    } else {
      notify("Error.");
    }
  };

  return (
    // @ts-ignore
    <Edit {...props}>
      <SimpleForm save={minorPatchSave} toolbar={<EditToolbar/>}>
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="tag" validate={[required()]}/>
        <TextInput source="overview" validate={[required()]}/>
        <TextInput source="parent" validate={[required()]}/>
        <ArrayInput source="features" >
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="removed" >
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="fixes" >
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="improvements">
          <SimpleFormIterator>
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
}
export const MinorPatchCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [majorPatches, setMajorPatches] = useState([]);

  useEffect(() => {
    const customParameters = {
      filter: {},
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'DESC' },
    };

    dataProvider
      .getList('major_patches', customParameters)
      .then((response) => {
        const mappedMajorPatches = response.data.map((majorPatch) => ({
          id: `/major_patches/${majorPatch.id}`,
          tag: majorPatch.tag,
        }));
        setMajorPatches(mappedMajorPatches);
      })
      .catch((error) => {
        console.error('Error fetching major patches:', error);
      });
  }, []);
  const minorPatchSave = async (vars) => {
    if(vars.tag) {
      let params = {
        "name": vars.name,
        "title": vars.title,
        "parent": vars.parent,
        "features": vars.features,
        "removed": vars.removed,
        "fixes": vars.fixes,
        "improvements": vars.improvements,
        "tag": vars.tag,
        "overview": vars.overview,
      }

      dataProvider.create('minor_patches', {data: params}).then(() => {
        notify(`Minor Patch created`);
        window.history.back();
      }).catch((err) => {
        console.log(err);
        notify("Error while creating minor patch. Please check the required fields!")
      })
    } else {
      notify("Error.");
    }
  };
  return (
    <Create {...props}>
      <SimpleForm save={minorPatchSave} >
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="title" validate={[required()]}/>
        <TextInput source="tag" validate={[required()]}/>
        <TextInput source="overview" validate={[required()]}/>
        <SelectInput source="parent" choices={majorPatches} optionText="tag" optionValue="id" validate={[required()]}/>
        <ArrayInput source="features" validate={[]} >
          <SimpleFormIterator>
            <TextInput source="name" validate={[]} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="removed" validate={[]}>
          <SimpleFormIterator>
            <TextInput source="name" validate={[]} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="fixes">
          <SimpleFormIterator>
            <TextInput source="name"/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="improvements">
          <SimpleFormIterator>
            <TextInput source="name"/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
};
