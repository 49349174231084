import env from "react-dotenv";
import decodeJwt from 'jwt-decode';
import axios from "axios";
import {apiResources} from "../config/apiResources";

const authProvider = {
    login: (params) => {
        const {userAddress, signature, operatorId} = params;
        const request = new Request(apiResources.authenticationToken.url, {
            method: 'POST',
            body: JSON.stringify({
                publicAddress: userAddress,
                signature: signature,
                operator: `operators/${operatorId}`,
            }),
            credentials: 'include',
            headers: new Headers({'Content-Type': 'application/json'}),
        })

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(() => {
              const request = new Request(`${env.HYVE_API_ENTRYPOINT}/users/logged`, {
                method: 'GET',
                credentials: 'include',
                headers: new Headers({'Content-Type': 'application/json'}),
              })

              return fetch(request);
            })
            .then((response) => {
              if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
              }

              return response.json();
            })
            .then((data) => {
              if (data.roles.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0) {
                localStorage.setItem('authenticated', 'true');
                localStorage.setItem('permissions', data.roles);
              } else {
                throw new Error('You are not authorised to access this.')
              }
            })
    },

    checkAuth: () => {
        return localStorage.getItem('authenticated') ? Promise.resolve() : Promise.reject();
    },

    getPermissions: () => {
        if (!localStorage.getItem('permissions')) {
          return Promise.reject();
        }
        const role = localStorage.getItem('permissions').split(',');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    checkError: (error) => {
        if(error.code === 401) {
            localStorage.removeItem('authenticated');
        }

        throw {
            code: 500,
            message: error ? error : 'Something went wrong.'
        }
    },
    logout: () => {
        localStorage.removeItem('authenticated');
        return Promise.resolve();
    },

    getUser: () => {
      const request = new Request(`${env.HYVE_API_ENTRYPOINT}/users/logged`, {
        method: 'GET',
        credentials: 'include',
        headers: new Headers({'Content-Type': 'application/json'}),
      })

      return fetch(request);
    }

    // getIdentity: () => {
    //
    // },

}

export default authProvider;

export const getUserByPublicAddress = async (publicAddress) => {
    const response = await axios(apiResources.users.url, {
        method: 'GET',
        params: {
            publicAddress: publicAddress,
            operatorId: env.HYVE_API_OPERATOR_ID
        }
    })

    const data = response.data;

    if (data['hydra:totalItems'] === 1) {
        return data['hydra:member'][0]
    }

    throw {
        code: 404,
        message: 'Could not find any user.'
    }
}

interface IDecodedToken {
    roles?: any,
    id?: string,
    exp?: number,
    int?: number,
}
