import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm,
  TextField, EditButton, ImageField, ImageInput, BooleanField, BooleanInput, DateTimeInput, useNotify,
  TextInput, required, useDataProvider, Toolbar, SaveButton

} from 'react-admin';
import Button from "@material-ui/core/Button";

const toUTC= (value) => {
  if(typeof value === "string") {
    value = new Date(value);
  }
  return value.toISOString();
}

export const UploadFile = async (file, dataProvider) => {
  let formData = new FormData();
  formData.append('file', file);
  return await dataProvider.create('assets/upload_file', {data: formData}).catch((err) => {
    console.log(err);
    return false
  })
}
export const BannerList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="name"/>
        <TextField source="dateFrom"/>
        <TextField source="dateTo"/>
        <ImageField source="desktopBig.remoteUri" title="desktop" label="Desktop Big" />
        <ImageField source="desktopSmall.remoteUri" title="desktop" label="Desktop Small" />
        <ImageField source="smartphone.remoteUri" title="Smartphone" label="Smartphone" />
        <ImageField source="tablet.remoteUri" title="Tablet" label="Tablet" />
        <TextField source="url"/>
        <BooleanField source="hidden"/>
        <EditButton basePath="/banners"/>
      </Datagrid>
    </List>
  );
}

const ListingEditToolbar = props => (
  <Toolbar {...props} >
    <Button style={{ marginRight: 50 }} variant="outlined" onClick={ e => window.history.back()}> Back </Button>
    <SaveButton />
  </Toolbar>
);
export const BannerEdit = (props) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const bannerSave = async (vars) => {

    let desktopBig = null;
    let desktopSmall = null;
    let smartphone = null;
    let tablet = null;

    if (vars.desktopBig.rawFile) {
      desktopBig = await UploadFile(vars.desktopBig.rawFile, dataProvider)
      if(!desktopBig) {
        notify(`Desktop Big Asset was not edited`);
        return;
      }
    }

    if (vars.desktopSmall.rawFile) {
      desktopSmall = await UploadFile(vars.desktopSmall.rawFile, dataProvider)
      if(!desktopSmall) {
        notify(`Desktop Small Asset was not edited`);
        return;
      }
    }

    if (vars.smartphone.rawFile) {
      smartphone = await UploadFile(vars.smartphone.rawFile, dataProvider)
      if(!smartphone) {
        notify(`Smartphone Asset was not edited`);
        return;
      }
    }

    if (vars.tablet.rawFile) {
      tablet = await UploadFile(vars.tablet.rawFile, dataProvider)
      if(!tablet) {
        notify(`Tablet Asset was not edited`);
        return;
      }
    }

    let params = {
      "name": vars.name,
      "dateFrom": toUTC(vars.dateFrom),
      "dateTo": toUTC(vars.dateTo),
      "url": vars.url,
      "hidden": vars.hidden
    }

   if(desktopBig) {
     params['desktopBig'] = '/assets/' + desktopBig.data.id;
   }

    if(desktopSmall) {
      params['desktopSmall'] = '/assets/' + desktopSmall.data.id;
    }

    if(smartphone) {
      params['smartphone'] = '/assets/' + smartphone.data.id;
    }

    if(tablet) {
      params['tablet'] = '/assets/' + tablet.data.id;
    }

    dataProvider.update('banners', {data: params, "id": vars.id, previousData: null}).then((banner) => {
        notify("Banner Edited")
        window.history.back();
    }).catch((err) => {
      console.log(err);
      notify("Error while editing banner. Please check the dates!")
    })
  };

  return (
    // @ts-ignore
    <Edit title="Edit Banner" {...props}>
      <SimpleForm save={bannerSave} toolbar={<ListingEditToolbar/>} >
        <TextInput source="name" validate={[required()]}/>

        <TextField label={"Date will be converted in UTC and show to all people at the same time"}></TextField>
        <DateTimeInput source="dateFrom" validate={[required()]}/>

        <TextField label={"Date will be converted in UTC and show to all people at the same time"}></TextField>
        <DateTimeInput source="dateTo" validate={[required()]} />

        <TextInput source="url"/>
        <ImageField source="desktopBig.remoteUri" label="Current Desktop Big Image"/>
        <ImageInput source="desktopBig" label="Replacing Desktop Big Image" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageField source="desktopSmall.remoteUri" label="Current Desktop Small Image"/>
        <ImageInput source="desktopSmall" label="Replacing Desktop Small Image" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageField source="smartphone.remoteUri" label="Current Smartphone Image"/>
        <ImageInput source="smartphone" label="Replacing Smartphone Image" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageField source="tablet.remoteUri" label="Current Tablet Image"/>
        <ImageInput source="tablet" label="Replacing Tablet Image" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <BooleanInput source="hidden"></BooleanInput>
      </SimpleForm>
    </Edit>
  )
}

export const BannerCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const bannerSave = async (vars) => {
      const desktopBig = await UploadFile(vars.desktopBig.rawFile, dataProvider)
      if(!desktopBig) {
        notify(`Desktop Big Asset was not created`);
        return;
      }

      const desktopSmall = await UploadFile(vars.desktopSmall.rawFile, dataProvider)
      if(!desktopSmall) {
        notify(`Desktop Small Asset was not created`);
        return;
      }

      const smartphone = await UploadFile(vars.smartphone.rawFile, dataProvider)
      if(!smartphone) {
        notify(`Smartphone Asset was not created`);
        return;
      }

      const tablet = await UploadFile(vars.tablet.rawFile, dataProvider)
      if(!tablet) {
        notify(`Tablet Asset was not created`);
        return;
      }

    if( desktopBig && desktopSmall && smartphone && tablet) {
      let params = {
        "name": vars.name,
        "dateFrom": toUTC(vars.startDate),
        "dateTo": toUTC(vars.endDate),
        "desktopBig": '/assets/' + desktopBig.data.id,
        "desktopSmall": '/assets/' + desktopSmall.data.id,
        "smartphone": '/assets/' + smartphone.data.id,
        "tablet": '/assets/' + tablet.data.id,
        "url": vars.url,
        "hidden": vars.hidden
      }
      notify(`Asset Created`);

      dataProvider.create('banners', {data: params}).then(() => {
        notify(`Banner created`);
        window.history.back();
      }).catch((err) => {
        console.log(err);
        notify("Error while creating banner. Please check the dates!")
      })
    } else {
      notify("Error while creating asset")
    }
  };
  return (
    <Create {...props}>
      <SimpleForm save={bannerSave} >
        <TextInput source="name" validate={[required()]}/>
        <TextField label={"Date is represented in UTC time"}></TextField>
        <DateTimeInput source="startDate" validate={[required()]}/>
        <TextField label={"Date is represented in UTC time"}></TextField>
        <DateTimeInput source="endDate" validate={[required()]} />

        <TextInput source="url"/>

        <ImageInput source="desktopBig" label="Desktop Big" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageInput source="desktopSmall" label="Desktop Small" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageInput source="smartphone" label="Smartphone" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageInput source="tablet" label="Tablet" accept="image/*" validate={[required()]}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="hidden"/>
      </SimpleForm>
    </Create>
  )
};



