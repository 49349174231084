// in src/MyLogoutButton.js
import React from 'react';
import {connect} from 'react-redux';
import {Responsive, userLogout} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const LogoutButton = ({userLogout, ...rest}) => (
    <Responsive
        xsmall={
            <MenuItem
                onClick={userLogout}
                {...rest} //TODO: sanitizeRestProps
            >
                <ExitIcon/> Logout
            </MenuItem>
        }
        medium={
            <Button
                onClick={userLogout}
                size="small"
                {...rest}//TODO: sanitizeRestProps
            >
                <ExitIcon/> Logout
            </Button>
        }
    />
);

const redirectTo = '/login';
const myCustomUserLogout = () => userLogout(redirectTo);
export default connect(undefined, { userLogout: myCustomUserLogout })(LogoutButton);
