import * as React from "react";
import {
    List, Datagrid, Edit, SimpleForm,
    TextField, EditButton, useNotify,
    TextInput, required, useDataProvider, Toolbar, SaveButton, SelectInput, BooleanField, BooleanInput
} from 'react-admin';
import Button from "@material-ui/core/Button";

const statusChoices = [
    {id: 'Planned', name: 'Planned'},
    {id: 'In Progress', name: 'In progress'},
    {id: 'Completed', name: 'Completed'},
    {id: 'Declined', name: 'Declined'},
];
export const FeatureRequestList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="title"/>
                <TextField source="description"/>
                <BooleanField source="hidden"/>
                <TextField source="status" />
                <EditButton basePath="/features"/>
            </Datagrid>
        </List>
    );
}
const EditToolbar = props => (
    <Toolbar {...props} >
        <Button style={{ marginRight: 50 }} variant="outlined" onClick={ e => window.history.back()}> Back </Button>
        <SaveButton />
    </Toolbar>
);
export const FeatureRequestEdit = (props) => {

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const featureRequestSave = async (vars) => {
        let params = {
                "title": vars.title,
                "description": vars.description,
                "status": vars.status,
                "hidden": vars.hidden,
        }
        dataProvider.update('features', {"data": params, "id": vars.id, previousData:null}).then(() => {
                notify(`Feature request updated`);
                window.history.back();
        }).catch((err) => {
            console.log(err);
            notify("Error while updating feature request. Please check the required fields!")
        })
    };

    return (
        <Edit {...props}>
            <SimpleForm save={featureRequestSave} toolbar={<EditToolbar/>}>
                <TextInput source="title" validate={[required()]}/>
                <TextInput source="description" validate={[required()]}/>
                <SelectInput source="status" choices={statusChoices}/>
                <BooleanInput source="hidden"/>
            </SimpleForm>
        </Edit>
    );
}
