// in src/Dashboard.js
import axios from 'axios';
import env from "react-dotenv";
import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useDataProvider, Query} from 'react-admin';
import StakingInformation from 'src/components/StakingInformation';

export default () => (

    <Card>
        <CardHeader title="Welcome to the administration" />
        <CardContent>
            <StakingInformation/>
        </CardContent>
    </Card>
);