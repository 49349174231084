import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm,
  BooleanField, BooleanInput, TextField, EditButton,
  TextInput, Toolbar, SaveButton, SelectField, SelectInput,
} from 'react-admin';
import Button from "@material-ui/core/Button";

const statusChoices = [
    {id: 0, name: 'Draft'},
    {id: 1, name: 'Published'},
    {id: 2, name: 'Canceled'},
];
const typeChoices = [
    {id: 'job', name: 'Job'},
    {id: 'offer', name: 'Offer'},
    {id: 'task', name: 'Task'},
    {id: 'item', name: 'Digital Item'},

];

const listFilters = [
    <TextInput source="title" label="Search" alwaysOn/>,
    <TextInput source="country" label="Country" alwaysOn/>,
    <TextInput source="city" label="City" alwaysOn/>,
    <TextInput source="slug" label="Slug" alwaysOn/>,
    <SelectInput source="status" label="Status" choices={statusChoices} alwaysOn/>,
    <SelectInput source="listingType" label="Type" choices={typeChoices} alwaysOn/>,
    <BooleanInput source="hidden" label="Hidden" alwaysOn/>,
];

export const ListingList = props => (
  <List filters={listFilters} {...props} bulkActionButtons={false} >
    <Datagrid>
      <TextField source="title"/>
      <TextField label="Owner" source="user.publicAddress"/>
      <SelectField source="status" choices={statusChoices}/>
      <BooleanField source="hidden"/>
      <TextField source="type" label="Type"/>
      <TextField source="slug" label="Slug"/>
      <EditButton basePath="/listings"/>
    </Datagrid>
  </List>
);

const ListingEditToolbar = props => (
    <Toolbar {...props} >
        <Button style={{ marginRight: 50 }} variant="outlined" onClick={ e => window.history.back()}> Back </Button>
        <SaveButton />
    </Toolbar>
);

export const ListingEdit = (props) => {
    const transform = data => ({
        hidden: data.hidden
    });

    return (
        // @ts-ignore
        <Edit title="Edit Listing" {...props} transform={transform} >
            <SimpleForm toolbar={<ListingEditToolbar/>}>
                <TextField source="title"/>
                <BooleanInput source="hidden"/>
            </SimpleForm>
        </Edit>
    )
}

export const ListingCreate = (props) => (
    <Create title="Creating a listing is disabled" {...props}>
    </Create>
);
