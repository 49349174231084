import * as React from "react";
import env from "react-dotenv";
import Link from "@material-ui/core/Link";

const InstallMetamaskLink = (props) => {
    return (
        <label>
            You do not seem to have MetaMask installed.
            <br />
            <Link href={env.METAMASK_LINK} target="_blank">
                Click here to install
            </Link>
        </label>
    );
};

export default InstallMetamaskLink;