import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm,
    BooleanField, BooleanInput, TextField, EditButton,
    TextInput, Toolbar, SaveButton, useListContext
} from 'react-admin';
import Button from "@material-ui/core/Button";

const listReportFilters = [
    <TextInput source="q" label="Search" alwaysOn/>,
    // <BooleanInput source="status" label="Status"/>,
    <BooleanInput source="hidden" label="Hidden"/>,
];

const ListReport = ({record}) => {
    return (
        <Datagrid data={record.listingReports} ids={record.listingReportIds} total={record.listingReportIds.length}>
            <TextField label="ID" source="id"/>
            <BooleanField source="isReviewed"/>
            <TextField label="Reason" source="reason"/>
            <TextField label="Username" source="reportedBy.username"/>
            <TextField label="Reviewed By" source="reviewedBy.username"/>
            <EditButton basePath="/listing_reports" />
        </Datagrid>
    )
}

const ListReportGrouped = ({ids, data, basePath}) => {
    const groupReportByListing = {};

    const listingIds = [];

    for (const id of ids) {
        const report = data[id];

        if (groupReportByListing[report.listing.id] === undefined) {
            listingIds.push(report.listing.id)
            groupReportByListing[report.listing.id] = {
                id: report.listing.id,
                title: report.listing.title,
                user: report.listing.user,
                listingReports: {
                    [report.id]: report
                },
                listingReportIds: [report.id]
            }
        } else {
            groupReportByListing[report.listing.id].listingReports[report.id] = report;
            groupReportByListing[report.listing.id].listingReportIds.push(report.id)
        }
    }

    return (
        <Datagrid expand={ListReport} data={groupReportByListing} ids={listingIds}>
            <TextField label="Listing ID" source="id"/>
            <TextField label="Listing Title" source="title"/>
            <TextField label="Listing Owner" source="user.username"/>
        </Datagrid>
    );
}

export const ListingReportList = (props) => {
    return (
        <List filters={listReportFilters} {...props} bulkActionButtons={false}>
            <ListReportGrouped {...props}/>
        </List>
    );
}

const ListingReportEditToolbar = props => (
    <Toolbar {...props} >
        <Button style={{marginRight: 50}} variant="outlined" onClick={e => window.history.back()}> Back </Button>
        <SaveButton/>
    </Toolbar>
);

export const ListingReportEdit = (props) => {
    const transform = data => ({
        isReviewed: data.isReviewed
    });

    return (
        // @ts-ignore
        <Edit title="Edit Listing Report" {...props} transform={transform}>
            <SimpleForm toolbar={<ListingReportEditToolbar/>}>
                <TextField label="ID" source="id"/>
                <TextField label="Listing ID" source="listing.id"/>
                <TextField label="Listing Title" source="listing.title"/>
                <TextField label="Listing Owner" source="listing.user.username"/>
                <TextField label="Reported By" source="reportedBy.username"/>
                <TextField label="Reviewed By" source="reviewedBy.username"/>
                <TextField label="Reason" source="reason"/>
                <BooleanInput source="isReviewed"/>
            </SimpleForm>
        </Edit>
    )
}

export const ListingReportCreate = (props) => (
    <Create title="Creating a listing report is disabled" {...props}>
    </Create>
);
