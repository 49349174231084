// in src/customRoutes.js
import * as React from "react";
import { Route } from 'react-router-dom';
import {
    BlockchainCurrencyCreate,
    BlockchainCurrencyEdit,
    BlockchainCurrencyList,
    BlockchainCurrencyView
} from "./pages/BlockchainCurrency";

export default [
    <Route exact path="/blockchain_currencies/:currencyId" component={BlockchainCurrencyList} />,
    <Route exact path="/blockchain_currencies/:currencyId/view/:blockchainId" component={BlockchainCurrencyView} />,
    <Route exact path="/blockchain_currencies/:currencyId/edit/:blockchainId" component={BlockchainCurrencyEdit} />,
    <Route exact path="/blockchain_currencies/:currencyId/create/:blockchainId" component={BlockchainCurrencyCreate} />,
];