import { Admin, Resource } from "react-admin";
import env from "react-dotenv";
import dataProvider from './providers/dataProvider';
import authProvider from "./providers/authProvider";
import Login from "./pages/login/Login";
import LogoutButton from "./components/LogoutButton";
import {ListingList, ListingEdit} from "./pages/Listing";
import {ListingReportList, ListingReportEdit} from "./pages/ListingReport";
import {UserReportList, UserReportEdit} from "./pages/UserReport";
import {CurrencyCreate, CurrencyEdit, CurrencyList} from "./pages/Currency";
import {PopupEdit, PopupList, PopupCreate} from "./pages/Popup";
import {BannerEdit, BannerList, BannerCreate} from "./pages/Banner";
import {MajorPatchEdit, MajorPatchList, MajorPatchCreate} from "./pages/MajorPatch";
import {MinorPatchEdit, MinorPatchList, MinorPatchCreate} from "./pages/MinorPatch";
import {FeatureRequestEdit, FeatureRequestList} from "./pages/FeatureRequest";

import CustomRoutes from "./CustomRoutes";
import Dashboard from "./pages/Dashboard";
import { WithdrawRequestList } from "./pages/WithdrawRequest";

export default () => {
    return (
        <Admin
            title={"HYVE Admin"}
            customRoutes={CustomRoutes}
            // menu={Menu}
            authProvider={authProvider}
            loginPage={Login}
            logoutButton={LogoutButton}
            dataProvider={dataProvider}
            dashboard={Dashboard}
        >
            {permissions => [
                null,
                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                    ? <Resource name="listings" list={ListingList} edit={ListingEdit} />
                    : null,

                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                    ? <Resource name="currencies" list={CurrencyList} edit={CurrencyEdit} create={CurrencyCreate} />
                    : null,

                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                    ? <Resource name="listing_reports" list={ListingReportList} edit={ListingReportEdit}/>
                    : null,

                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                    ? <Resource name="user_reports" list={UserReportList} edit={UserReportEdit}/>
                    : null,

                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                    ? <Resource name="withdraw_requests" list={WithdrawRequestList}/>
                    : null,

                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                  ? <Resource name="popups" list={PopupList} edit={PopupEdit} create={PopupCreate} />
                  : null,

                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                  ? <Resource name="banners" list={BannerList} edit={BannerEdit} create={BannerCreate} />
                  : null,
                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                  ? <Resource name="major_patches" list={MajorPatchList} edit={MajorPatchEdit} create={MajorPatchCreate} />
                  : null,
                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                  ? <Resource name="minor_patches" list={MinorPatchList} edit={MinorPatchEdit} create={MinorPatchCreate} />
                  : null,
                permissions.indexOf(env.HYVE_API_ADMIN_ROLE) >= 0
                    ? <Resource name="features" list={FeatureRequestList} edit={FeatureRequestEdit} />
                    : null
          ]}
        </Admin>
    );
}
