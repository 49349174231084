import * as React from 'react';
import {useEffect, useState} from 'react';
import env from "react-dotenv";
import {ethers} from 'ethers';
import {
    useLogin,
    useNotify,
    defaultTheme,
    useSafeSetState,
    Login as BaseLogin, usePermissions,
} from 'react-admin';
import {getUserByPublicAddress} from "../../providers/authProvider"
import CardActions from "@material-ui/core/CardActions";
import {ThemeProvider} from '@material-ui/styles';
import {createTheme} from '@material-ui/core/styles';
import LoginButton from "../../components/LoginButton";
import InstallMetamaskLink from "../../components/InstallMetamaskLink";

const Login = (props) => {
    const login = useLogin();
    const [userAddress, setUserAddress] = useState('');
    const [loading, setLoading] = useSafeSetState(false);
    const notify = useNotify();
    const [hasMetamask, setHasMetamask] = useState(false);

    useEffect(() => {
        // @ts-ignore
        setHasMetamask(typeof window.ethereum !== "undefined");
    });


    const submit = async () => {
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(window.ethereum)

        try {
            setLoading(true);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            let userAddress = await signer.getAddress();

            setUserAddress(userAddress);

            const {nonce = null} = await getUserByPublicAddress(userAddress);
            let signature = await signer.signMessage("Please sign with your account: " + nonce);
            const operatorId = env.HYVE_API_OPERATOR_ID;

            await login({userAddress: userAddress, signature: signature, operatorId: operatorId});
        } catch (e) {
            notify(e.message, 'error', {}, false, 5000);
        }

        setLoading(false);
    };

    if(hasMetamask) {
        return (
            <ThemeProvider theme={createTheme(defaultTheme)}>
                <BaseLogin {...props}>
                    <CardActions>
                        <LoginButton onClick={submit} loading={loading}/>
                    </CardActions>
                </BaseLogin>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <BaseLogin {...props}>
                <CardActions>
                    <InstallMetamaskLink />
                </CardActions>
            </BaseLogin>
        </ThemeProvider>
    );
};

export default Login;